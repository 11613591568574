@import "~@/styles/variables/variables.scss";














































































































































































































































::v-deep .el-textarea__inner {
	height: 64px;
}
.box-tip {
	padding: 8px;
	background: #e9f1ff;
	border-radius: 3px;
	font-size: 12px;
	color: #2f87fe;
	line-height: 20px;
	display: flex;
	.iconfont {
		font-size: 12px;
		margin-right: 8px;
	}
	.download {
		color: #ff6a4d;
		text-decoration: underline;
	}
}
.el-form {
	padding-top: 20px;
}
.box-file {
	width: 100%;
	min-height: 88px;
	border-radius: 4px;
	@include flex-center;
	position: relative;
	.box-upload {
		position: absolute;
		width: 100%;
		height: 100%;
		::v-deep .el-upload {
			width: 100%;
			height: 100%;
			@include flex-center;
		}
	}
	&.empty {
		border: 1px dashed #d5d5d5;
		color: #909090;
	}
	&.selected {
		border: 1px solid #d9d9d9;
		color: #303030;
		justify-content: flex-start;
		padding-right: 30px;
		line-height: 20px;
		.icon-xls {
			margin-left: 16px;
			margin-right: 18px;
		}
		.iconfont {
			font-size: 12px;
			color: #c0c4cc;
			padding: 1px;
			cursor: pointer;
			position: absolute;
			right: 10px;
			border: 1px solid #c0c4cc;
			border-radius: 50%;
			transform: scale(0.8);
		}
	}
}
